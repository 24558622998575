@font-face {
  font-family: Kanit-Light;
  src: url(../assets/fonts/Kanit-Light.ttf);
  font-display: swap;
}

// fallback for Kanit Light
@font-face {
  font-family: Roboto-Light;
  src: url(../assets/fonts/Roboto-Light.ttf);
  font-display: swap;
}

@font-face {
  font-family: Kanit-Regular;
  src: url(../assets/fonts/Kanit-Regular.ttf);
  font-display: swap;
}

@font-face {
  font-family: Tanker-Regular;
  src: url(../assets/fonts/Tanker-Regular.ttf);
  font-display: swap;
}

// fallback for Kanit and Tanker Regular
@font-face {
  font-family: Roboto-Regular;
  src: url(../assets/fonts/Roboto-Regular.ttf);
  font-display: swap;
}

@font-face {
  font-family: Kanit-SemiBold;
  src: url(../assets/fonts/Kanit-SemiBold.ttf);
  font-display: swap;
}

@font-face {
  font-family: Kanit-Medium;
  src: url(../assets/fonts/Kanit-Medium.ttf);
  font-display: swap;
}

// fallback for Kanit SemiBold amd Kanit Medium
@font-face {
  font-family: Roboto-Medium;
  src: url(../assets/fonts/Roboto-Medium.ttf);
  font-display: swap;
}

@font-face {
  font-family: Kanit-Bold;
  src: url(../assets/fonts/Kanit-Bold.ttf);
  font-display: swap;
}

// fallback for Kanit Bold
@font-face {
  font-family: Roboto-Bold;
  src: url(../assets/fonts/Roboto-Bold.ttf);
  font-display: swap;
}

html,
body {
  width: 100%;
}

::-webkit-scrollbar {
  width: 0;
  /* Remove scrollbar space */
  background: transparent;
  /* Optional: just make scrollbar invisible */
}

html,
body,
#root,
.App {
  margin: 0;
  height: 100%;
  overflow-y: auto;
}

.full-height {
  height: 100%;
}

body {
  background-color: $blue-100;
}

@media (max-width: 1023px) {

  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    padding: 0;
    max-width: 100%;
  }
}

@media (min-width: $large) {

  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 100%;
  }
}

@media (min-width: $xx-large) {

  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1600px;
  }
}

.game-holder {
  width: 100%;
  min-height: calc(100% - 55px);
  padding: 0;
  padding-bottom: 250px; // height of bottom navigator and ticket
  background-color: $primary-color;

  @media (min-width: $large) {
    background-color: transparent;
    padding: .5rem 0;
  }

  @media (min-width: $x-large) {
    padding-bottom: 0.5rem;
  }

  &.game-holder-active-auto-play {
    padding-bottom: 340px; // height of bottom navigator and ticket and autoplay button

    @media (min-width: $x-large) {
      padding-bottom: 1rem;
    }
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: 100%;
  }
}

.game-holder-mobile {
  width: 100%;
  height: 100%;
}

.game-holder-left {
  width: 100%;
  // height: calc(100vh - 144px); // height of header and pt 2rem and pb 2rem
  // max-height: 100%;
  height: 90vh;
  // height: 966px;
  margin-right: 10px;
  background-color: $blue-200;
  border-radius: 10px;
  max-width: 360px;
  max-height: 1000px;

  @media (min-width: $large) {
  }
  @media (min-width: $xx-large) {
    max-width: 400px;
  }

  .tab-content {
    height: calc(100% - 48px); // minus height of the .game-holder-tabs height

    .tab-pane {
      height: 100%;
    }
  }
}

.results {
  .tab-content {
    overflow-y: auto;
    padding: 10px;
    padding-top: 0;
    height: calc(100% - 52px); // minus height of the .results-tabs height

    @media (min-width: $large) {
      height: calc(100% - 70px); // minus height of the .results-tabs height
    }

    .tab-pane {
      height: 100%;
    }
  }
}

.game-holder-tabs {
  border: 1px solid $blue-300;
  background-color: $primary-color;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  height: 55px;

  .nav-item {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    cursor: pointer;
    flex: 1;

    &:first-child {
      .nav-link {
        border-top-left-radius: 10px;
      }
    }

    &:last-child {
      .nav-link {
        border-top-right-radius: 10px;
      }
    }
  }

  .nav-link {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: rgba(255, 255, 255, 0.5);
    padding: 14px;
    font-family: $font-regular;
    border-radius: 0;
    border: 1px solid transparent;

    &:hover {
      border: 1px solid transparent;
      border-bottom: 1px solid $blue-300;
    }

    &.active {
      background: linear-gradient(136.75deg,
          rgba(52, 57, 81, 0.95) -0.39%,
          rgba(40, 45, 71, 0.95) 104.15%);
      color: rgba(255, 255, 255, 1);
      border: 1px solid transparent;
      border-bottom: 1px solid $orange-100;

      &:hover {
        border: 1px solid transparent;
        border-bottom: 1px solid $orange-100;
      }
    }
  }
}

.results-tab {
  padding-bottom: 1px;
  padding-top: 10px;

  @media (min-width: $large) {
    padding-top: 0;
  }
}

.my-results-reversed {
  display: flex;
  flex-direction: column-reverse;
}

.game-holder-right {
  position: relative;
  padding: 10px;
  background-color: $primary-color;
  width: 100%;
  border-radius: 0;

  @media (min-width: $large) {
    border-radius: 10px;
  }
}

.bottom-game-holder {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  margin-top: 10px;

  @media (min-width: $x-large) {
    flex-direction: row;
  }
}

.bottom-game-holder-ticket {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-left: 0;
  margin-bottom: 10px;

  @media (min-width: $large) {
    flex-direction: row;
  }

  @media (min-width: $x-large) {
    flex-direction: column;
    margin-left: 10px;
    margin-bottom: 0;
  }
}

.game-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  font-family: $font-semibold;
  font-size: 14px;
  color: white;
}

.game-header-text {
  font-size: 16px;
  color: $blue-600;
}

.primary-button,
.secondary-button {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 38px;
  font-size: 16px;
  font-family: $font-semibold;
  color: white;
  border-radius: 6px;
  border: 1px solid;

  @media (min-width: $large) {
    height: 42px;
    font-size: 20px;
  }

  &.small {
    font-size: 14px;
  }

  &::before {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: transparent;
    border-radius: 6px;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: .5;
  }
}

.primary-button {
  font-size: 16px;
  background: linear-gradient(117.43deg, #FB902D 26.8%, #FF7B00 71.94%);
  box-shadow: 0px 0px 10px #FB902D;
  border-image-source: linear-gradient(159.82deg, rgba(255, 255, 255, 0.5) -11.93%, rgba(255, 255, 255, 0) 50.86%, rgba(255, 255, 255, 0.5) 114.52%);


  @media (min-width: $large) {
    font-size: 20px;
  }

  &::before {
    box-shadow: 0 0 0 0 #FB902D, 0 4px 0 0 #8F4500;
    @include transition(transform 150ms cubic-bezier(0, 0, 0.58, 1));
  }

  &:disabled {
    &:active {
      transform: none;

      &::before {
        box-shadow: 0 0 0 0 #FB902D, 0 4px 0 0 #8F4500;
      }
    }
  }

  &:active {
    transform: translate(0em, 1px);

    &::before {
      box-shadow: 0 0 0 0 #FB902D, 0 0 0 0 #8F4500;
    }
  }
}

.secondary-button {
  background: linear-gradient(117.43deg, #6CAFFF 26.8%, #3D95FF 71.94%);
  box-shadow: 0px 0px 10px #3D95FF;
  border-image-source: linear-gradient(159.82deg, rgba(255, 255, 255, 0.5) -11.93%, rgba(255, 255, 255, 0) 50.86%, rgba(255, 255, 255, 0.5) 114.52%);

  &::before {
    box-shadow: 0 0 0 0 #3D95FF, 0 4px 0 0 #1F7BE8;
    @include transition (transform 150ms cubic-bezier(0, 0, 0.58, 1));
  }

  &:disabled {
    &:active {
      transform: none;

      &::before {
        box-shadow: 0 0 0 0 #3D95FF, 0 4px 0 0 #1F7BE8;
      }
    }
  }

  &:active {
    transform: translate(0em, 1px);

    &::before {
      box-shadow: 0 0 0 0 #3D95FF, 0 0 0 0 #1F7BE8;
    }
  }
}

.button-only-text {
  width: 100%;
  height: auto;
  padding: 0;
  border: none;
  font-family: $font-regular;
  font-size: 14px;
  color: $orange-100;
  text-align: center;
  background-color: transparent;
}

.quit-autoplay-mobile {
  padding: 8px 0;
  background-color: $primary-color;
}

.red-ball {
  background-color: $red-ball;
}

.green-ball {
  background-color: $green-ball;
}

.blue-ball {
  background-color: $blue-ball;
}

.purple-ball {
  background-color: $purple-ball;
}

.brown-ball {
  background-color: $brown-ball;
}

.yellow-ball {
  background-color: $yellow-ball;
}

.orange-ball {
  background-color: $orange-ball;
}

.black-ball {
  background-color: $black-ball;
}

.default-ball {
  background-color: rgba(10, 13, 46, 0.5);
}

.bouncing {
  z-index: 2;
  animation: jumping ease-in-out normal infinite;
  @include transition(.2s transform ease-in-out);
  // animation-duration: .7s; 
}

@keyframes jumping {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.25);
  }

  100% {
    transform: scale(1);
  }
}

.chat-spam {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: black;
  z-index: 1;
  padding: .5rem;
  background: rgba(16, 21, 43, 1);

  @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
    background-color: rgba(16, 21, 43, 0.9);
    -webkit-backdrop-filter: blur(4px);
    backdrop-filter: blur(4px);
  }

  .progress {
    border-radius: 5px;
    height: .5rem;
  }

  .progress-bar {
    background: linear-gradient(270deg, #3D95FF 0%, #4487FC 44.44%, #5C28FB 84.48%);
    border-radius: 5px;
  }
}

.chat-spam-text {
  font-family: $font-regular;
  font-size: 14px;
  color: $gray-200;
  margin-bottom: .5rem;

  span {
    color: white;
  }
}

.loading-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100000;
  background: radial-gradient(71.58% 71.58% at 74.58% 28.42%, #364166 0%, #212840 100%);
}

.loading-screen-inapp {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background: radial-gradient(71.58% 71.58% at 74.58% 28.42%, #364166 0%, #212840 100%);
}

.loading-screen-background {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.loading-screen-text {
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
  bottom: 2rem;
}

.loading-screen-dot-holder {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: $font-semibold;
  font-size: 14px;
  color: white;
}

.loading-bullet-holder {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.loading-bullet {
  width: 12px;
  height: 12px;
  border-radius: 100%;
  margin: 0 6px;

  &:nth-child(1) {
    background: radial-gradient(50% 50% at 45.84% 50%, #FFB066 0%, #FB902D 100%);
    border: 1px solid #462200;
    box-shadow: 0px 0px 10px #FB902D;
    animation: dotElasticBefore 1.5s ease-in-out normal infinite;
    @include transition(.3s transform ease-in-out);
  }

  &:nth-child(2) {
    background: radial-gradient(50% 50% at 45.84% 50%, #53FFB0 0%, #1CE287 100%);
    border: 1px solid #055337;
    box-shadow: 0px 0px 10px #1CE287;
    animation: dotElastic 1.5s ease-in-out normal infinite;
    @include transition(.3s transform ease-in-out);
  }

  &:nth-child(3) {
    background: radial-gradient(50% 50% at 45.84% 50%, #5AF3FF 0%, #19CBD9 100%);
    border: 1px solid #054D53;
    box-shadow: 0px 0px 10px #5AF3FF;
    animation: dotElasticAfter 1.5s ease-in-out normal infinite;
    @include transition(.3s transform ease-in-out);
  }
}

.notify-screen {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  opacity: 0.8;
  background: radial-gradient(71.58% 71.58% at 74.58% 28.42%, #364166 0%, #212840 100%);
}

@keyframes dotElasticBefore {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(1.25);
  }

  50% {
    transform: scale(1);
  }

  75% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes dotElastic {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.25);
  }

  75% {
    transform: scale(1);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes dotElasticAfter {
  0% {
    transform: scale(1);
  }

  25% {
    transform: scale(1);
  }

  50% {
    transform: scale(1);
  }

  75% {
    transform: scale(1.25);
  }

  100% {
    transform: scale(1);
  }
}

// Emoji

.emoji-container {
  position: absolute;
  bottom: 72px;
  right: 1px;

  &.hide-emoji {
    display: none;
  }
}

aside.emoji-picker-react {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  box-shadow: none !important;
  border: none !important;
  background: linear-gradient(116.41deg,
      #d5d7ea 33.41%,
      #ffbf1a 212.04%) !important;
}

.emoji-search,
.emoji-picker-react .emoji-group:before {
  color: $blue-400  !important;
  z-index: 5 !important;
  position: relative !important;
  background: transparent !important;
}

.emoji-picker-react input.emoji-search {
  border: 1px solid $blue-400  !important;
}

// Gifs picker 

.chat-gifs-holder {
  position: absolute;
  bottom: 72px;
  right: 1px;
  height: 320px;
  width: 280px;
  padding: 15px;
  background: linear-gradient(116.41deg, #d5d7ea 33.41%, #ffbf1a 212.04%);
  overflow-y: auto;
  border-top-left-radius: 5px;
}

.chat-gifs-title {
  font-family: sans-serif;
  font-weight: 700;
  font-size: 14px;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
}

.chat-gifs-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin: 0.25rem -0.25rem;
}

.chat-gifs-category {
  position: relative;
  flex: 1;
  margin: 0.25rem;
  background-size: cover;
  border-radius: 5px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
  }

  .chat-gifs-category-title {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    @include transition(0.3s all ease-in-out);
    border-radius: 5px;
    margin-bottom: 0;
    font-family: $font-semibold;
    font-size: 16px;
    color: white;

    &:hover {
      background-color: rgba(0, 0, 0, 0.6);
    }
  }

  &.loss-category {
    // background-size: cover;
    // background: url(https://media3.giphy.com/media/EXHHMS9caoxAA/giphy.gif) no-repeat center center fixed;
  }
}

// Main menu
.menu-page {
  display: flex;
  flex-direction: column;
}

.menu-item {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.menu-item-title {
  width: 100%;
  color: #fff;
  margin: auto;
  font-size: 14px;
  padding: 0 .5rem;
}

.menu-item-separator {
  width: 100%;
  background: #6c757d;
  height: 1px;
}


.menu-item-switch-holder {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  min-height: 40px !important;
  /* justify-content: space-between; */
  /* align-items: center; */
  margin: 3px;
  color: white;
  padding: .25rem .5rem;
}

.react-switch {
  background-color: #202558;
  border: 2px solid #202558;
}

.switcher-off-inactive,
.switcher-on-inactive,
.switcher-off,
.switcher-on {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 28px;
  border-radius: 12px;
  line-height: 1;
  font-family: $font-semibold;
  font-size: 14px;
  color: white;
}

.switcher-off {
  background-color: $blue-400;

  &:focus {
    background-color: $blue-400  !important;
    box-shadow: none !important;
  }
}

.switcher-on {
  background: $yellow-100;
  color: $blue-400;

  &:hover {
    background: linear-gradient(116.37deg, #fabf41 16.45%, #c99308 96.03%);
  }

  &:focus {
    background: linear-gradient(116.37deg, #fabf41 16.45%, #ffbf1a 96.03%);
    @include boxShadow(0px 0px 15px rgba(255, 191, 26, 0.8));
  }
}

.switcher-off-inactive,
.switcher-on-inactive {
  opacity: 0.5;
  color: white !important;
  padding-top: 2px;
}

.react-switch-handle {
  height: 28px !important;
  width: 50px !important;
  background-color: $blue-400  !important;
}

.move-left {
  .react-switch-handle {
    transform: translateX(49px) !important;
  }
}

.avatar-item {
  width: 50px;
  height: 50px;
  background: transparent;
  color: $blue-400;

  &:hover {
    background: linear-gradient(116.37deg, #fabf41 16.45%, #c99308 96.03%);
  }

  &:focus {
    background: linear-gradient(116.37deg, #fabf41 16.45%, #ffbf1a 96.03%);
    @include boxShadow(0px 0px 15px rgba(255, 191, 26, 0.8));
  }

  img{
    width: 100%;
  }

  @media(max-width: 992px) and (orientation: portrait){
    width: 50px;
    height: 50px;
  }
  @media(max-width: 992px) and (orientation: landscape){
    width: 40px;
    height: 40px;
  }
  @media(min-width: 992px){
      width: 65px;
      height: 65px;
  }
  @media(min-width: 1178px){
      width: 100px;
      height: 100px;
  }
}

.avatar-holder {
  display: flex;
  width: 100%;
  flex-flow: wrap;
  margin: auto;
  justify-content: center;
}

.avatar-selected {
  background: linear-gradient(116.37deg, #fabf41 16.45%, #c99308 96.03%);
}

.change-avatar-fade-container {
  // width: 60%;

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  /* align-items: center; */
  height: 100%;
  text-align: center;
}

@media (max-width: 1178px) {
  .avatar-holder {
    width: 90%;
    // height: calc(75vh)
  }

  // .change-avatar-fade-container {
  //   width: 80%;
  // }

}

@media (min-width: 1178px) {
  .avatar-holder {
    width: 89%;
  }
}

@media (min-width: 2178px) {
  .avatar-holder {
    width: 59%;
  }
}

.header-credit {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $font-regular;
  padding: 0 .5rem;
}

.header-credit-currency {
  font-family: $font-medium;
  font-size: 10px;
  margin-bottom: -4px;
  margin-left: 2px;
  color: rgba(255, 255, 255, 0.5);
}

.bet-rounds-ball-odd-bonus {
  font-family: "Kanit-Light", Roboto-Light;
  font-size: 12px;
  text-align: center;
  color: rgb(255 255 255);
  height: 18px;
}

@keyframes winningAnim {
  from {
    background: linear-gradient(to right, #197D0E 0%, #4AFF2E 100%);
  }

  to {
    background: linear-gradient(to right, #197D0E 0%, #4AFF2E 0%);
  }


}


@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

@keyframes gradientUpDown {
  0% {
    background-position: 0% 0%;
  }

  50% {
    background-position: 0% 150%;
  }

  100% {
    background-position: 0% 0%;
  }
}

.winner {
  // background: linear-gradient(-45deg, #abc913, #368505, #0ce889, #368505, #2cd523, #65b200);
  background: linear-gradient(45deg,
      transparent,
      #1B8366,
      transparent,
      #156750,
      transparent,
    );
  background-size: 600% 600%;
  animation: gradient 5s ease-in-out 1;

  .results-box-footer-info {
    color: white;

    label {
      color: white;
    }
  }

}

.jackpots-holder {
  display: flex;
  margin: 0 -5px;
  position: relative;
  min-height: 25px;
}

.jackpot-btn-holder {
  min-width: auto;
  // max-width: 37.33%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2px;

  @media (min-width: $large) {
    min-width: 135px;
    padding: 0 5px;
  }
}

.jackpot-btn {
  position: relative;
  width: 100%;
  height: 28px;
  color: white;
  background-color: transparent;
  border: solid 1px;
  border-color: #fb902d;
  box-shadow: 0px 0px 10px #fb902d;
  border-radius: 12px;

  @media (min-width: $large) {
    height: 32px;
  }

  &:hover {
    color: white;
    border-color: #fb902d;
  }
}


.jackpot-btn-title {
  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  color: white;
  height: 24px;
  text-transform: uppercase;
  font-family: $font-medium;
  font-size: 14px;

  @media (min-width: $x-large) {
  font-size: 16px;
  }
}

.jackpot-menu {
  cursor: pointer;
  position: absolute;
  width: 360px;
  background: #101831;
  height: auto;
  z-index: 9999;
  top: 60px;
  left: 50%;
  transform: translate(-50%, 0);
}

.jackpot-currency {
  font-size: 10px;
  color: rgba(255, 255, 255, 0.5);
  padding-left: 1px;
  margin-bottom: -4px;
}

.jackpot-value-holder {
  display: flex;
  margin-top: -3px;
  justify-content: center;
  align-items: center;
}

.JPHistory-holder {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: white;
}

.JPHistory {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 1px;
}

.JPHistory-item-data {
  width: 27%;
  text-align: center;
  font-size: 12px;
}

.JPHistory-item-user {
  width: 50%;
  text-align: left;
  font-size: 12px;
  padding-left: 5px;
}

.JPHistory-item {
  width: 25%;
  text-align: center;
  font-size: 12px;
}

.avatar-menu {
  font-family: $font-medium;
  font-size: 16px;
  color: white;

  &.mobile {
    display: flex;
    justify-content: center;
    min-height: 30px;
    margin: 3px;
    padding-top: 5px;

    @media (min-width: $large) {
      display: none;
    }
  }

  &.desktop {
    display: none;

    @media (min-width: $large) {
      display: flex;
    }
  }
}

.avatar-dropdown,
.main-menu-dropdown {
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 55px;
  width: 100%;
  height: auto;
  padding-top: 10;
  background: #101831;
  z-index: 99999;
  overflow: hidden;

  @media (min-width: $large) {
    max-width: 320px;
    right: 0.75rem;
  }
}

.avatar-dropdown {
  .menu-item-switch-holder {
    min-height: 30px !important;
  }

  .menu-item-title {
    text-align: center;
  }
}

.balance-holder {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}

.get-back-button {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  width: 120px;
  background-color: rgba(38, 44, 69, .5);
  font-family: $font-medium;
  color: white;
  border-radius: 6px;
  line-height: 1;
  height: 28px;
  font-size: 14px;

  svg {
    margin-left: 5px;
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.demo-badge {
  width: 85px;
  height: 88px;
  overflow: hidden;
  position: absolute;
  top: -6px;
  left: -7px;
  z-index: 99;
}

.demo-badge .sticker {
  font-family: $font-bold;
  font-size: 14px;
  color: white;
  text-align: center;
  text-shadow: rgba(255, 255, 255, 0.5) 0px 1px 0px;
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  position: relative;
  padding: 7px 0;
  left: -29px;
  top: 17px;
  width: 120px;
  background-color: $red-100;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#BFDC7A), to(#8EBF45));
  background-image: -webkit-linear-gradient(top, #EF5350, #F44336);
  background-image: -moz-linear-gradient(top, #EF5350, #F44336);
  background-image: -ms-linear-gradient(top, #EF5350, #F44336);
  background-image: -o-linear-gradient(top, #EF5350, #F44336);
  color: #FFFFFF;
  -webkit-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
}

.fs-clock {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 15px;
  color: white;
}