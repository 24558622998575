.bottom-navigator {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 70px;
    border-top: 1px solid #1C2443;
    background-color: rgba(16, 21, 43, 0.9);
    display: flex;
    backdrop-filter: blur(4px);
    z-index: 10;

    &.backdrop-blur {
        background-color: rgba(16, 21, 43, 0.9);
    }

    @supports ((-webkit-backdrop-filter: none) or (backdrop-filter: none)) {
        &.backdrop-blur {
            background-color: rgba(16, 21, 43, 0.9);
            -webkit-backdrop-filter: blur(4px);
            backdrop-filter: blur(4px);
        }
    }
}

.bottom-navigator-link {
    position: relative;
    padding: 0.5rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    font-family: $font-regular;
    font-size: 16px;
    color: rgba(255, 255, 255, .5);
    text-decoration: none;

    &:nth-child(2) {
        border-left: 1px solid #1C2443;
        border-right: 1px solid #1C2443;
    }

    &.active {
        color: white;

        svg {
            path {
                fill: $orange-100;
                opacity: 1;
            }
        }
    }
}