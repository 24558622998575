.choose-amount {
    position: fixed;
    bottom: 70px;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 10px;
    background-color: rgba(16, 21, 43, 0.95);
    flex: 1;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    z-index: 999;

    @media (min-width: $large) {
        position: relative;
        bottom: inherit;
        left: inherit;
        right: inherit;
    }

    .game-header {
        display: none;

        @media (min-width: $large) {
            display: flex;
        }
    }
}

.choose-amount-body {
    display: flex;
    flex-direction: column;
}

.choose-amount-input-holder {
    display: flex;
    height: 36px;

    @media (min-width: $x-large) {
        height: 42px;
    }
}

.choose-amount-input {
    flex: 1;
    height: 100%;
    font-family: $font-medium;
    font-size: 0 18px;
    color: white;
    background-color: rgba(38, 44, 69, .5);
    text-align: center;
    border: none;
    border-left: 1px solid rgba(255, 255, 255, .3);
    border-right: 1px solid rgba(255, 255, 255, .3);

    &:focus-visible {
        outline: none;
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 1;
    }
}

/* Chrome, Safari, Edge, Opera */
input.choose-amount-input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input.choose-amount-input[type=number] {
    -moz-appearance: textfield;
}

.choose-amount-input-button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: $font-semibold;
    font-size: 18px;
    color: $orange-100;
    padding: 0 18px;
    height: 100%;
    background-color: rgba(38, 44, 69, .5);
    border: none;

    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }

    &.minus {
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
    }

    &.plus {
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;
    }
}

.choose-amount-button-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin: -5px;
    margin-top: 10px;
}

.choose-amount-button-holder {
    width: 25%;
    padding: 5px;

    @media (min-width: $x-large) {
        width: 50%;
    }
}


.choose-amount-button {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    width: 100%;
    background-color: rgba(38, 44, 69, .5);
    font-family: $font-medium;
    color: white;
    border-radius: 6px;
    line-height: 1;
    height: 36px;
    font-size: 16px;
    flex-direction: column;

    &:focus {
        animation: choose-amount-bounce .6s ease-in-out normal forwards;
    }

    &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
    }

    @media (min-width: $large) {
      font-size: 10px;
    }

    @media (min-width: $x-large) {
        height: 40px;
        font-size: 18px;
        flex-direction: row;
    }

    .choose-amount-button-currency {
        font-size: 10px;
        color: rgba(255, 255, 255, .5);
        margin-left: 0;
        margin-bottom: 0;

        @media (min-width: $x-large) {
            margin-left: 2px;
            margin-bottom: -6px;
        }
    }
}

@keyframes choose-amount-bounce {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(.95);
    }

    75% {
        transform: scale(1.05);
    }

    100% {
        transform: scale(1);
    }
}

.place-bet-wrapper {
    display: flex;
    margin: -5px;
    margin-top: .5rem;

    @media (min-width: $large) {
    margin-top: 1rem;
    }
}

.place-bet-button-holder {
    padding: 5px;

    &.place-bet-button-holder-short {
        width: 40%;

        @media (min-width: $xx-large) {
            width: 30%;
        }
    }

    &.place-bet-button-holder-long {
        width: 60%;

        @media (min-width: $xx-large) {
            width: 70%;
        }
    }

    &.place-bet-button-holder-full {
        width: 100%;
    }
}