@mixin transition($value) {
  -webkit-transition: $value; /* Safari/Chrome, other WebKit */
  -moz-transition: $value; /* Firefox, other Gecko */
  -o-transition: $value; /* Opera/IE 8+ */
  transition: $value;
}

@mixin transform($value) {
  -webkit-transform: $value; /* Safari/Chrome, other WebKit */
  -moz-transform: $value; /* Firefox, other Gecko */
  -o-transform: $value; /* Opera/IE 8+ */
  transform: $value;
}

@mixin boxShadow($value) {
  -webkit-box-shadow: $value; /* Safari/Chrome, other WebKit */
  -moz-box-shadow: $value; /* Firefox, other Gecko */
  -o-box-shadow: $value; /* Opera/IE 8+ */
  box-shadow: $value;
}

@mixin animationDelay($value) {
  -webkit-animation-delay: $value; /* Safari/Chrome, other WebKit */
  -moz-animation-delay: $value; /* Firefox, other Gecko */
  -o-animation-delay: $value; /* Opera/IE 8+ */
  animation-delay: $value;
}

@mixin boxSizing($value) {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box;
}

@mixin dropShadow($value) {
  -webkit-filter: $value; /* Safari/Chrome, other WebKit */
  -moz-filter: $value; /* Firefox, other Gecko */
  -o-filter: $value; /* Opera/IE 8+ */
  filter: $value;
}

@mixin placeholder {
  &::-webkit-input-placeholder {
    @content;
  }
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
}

@mixin noSelect {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}