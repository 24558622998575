.results {
  color: white;
  height: 100%;
  background-color: $blue-200;
  padding-bottom: 70px;

  @media (min-width: $large) {
    padding-bottom: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;

  }
}

.results-tabs {
  padding: 0;
  border: none;
  background-color: $blue-200;
  display: flex;
  justify-content: space-between;
  height: 52px;

  @media (min-width: $large) {
    padding: 1rem 10px;
    height: 70px;
  }

  .nav-item {
    flex: 1;
    padding: 0;

    @media (min-width: $large) {
      padding: 0 2px;
    }

    @media (min-width: $xx-large) {
      flex: 1;
    }
  }

  .nav-link {
    width: 100%;
    color: rgba(255, 255, 255, 0.5);
    height: 100%;
    font-family: $font-regular;
    border-radius: 0;
    border: 1px solid transparent;
    border-bottom: 1px solid #353D62;
    line-height: 1;
    font-size: 14px;
    padding: 6px;

    @media (min-width: $large) {
      height: 38px;
      border-radius: 19px;
      border: 1px solid $blue-400;
    }

    @media (min-width: $xx-large) {
      padding: 2px 28px;
    }

    &:hover {
      border: 1px solid $blue-400;
      isolation: unset;
    }

    &.active {
      background: linear-gradient(136.75deg,
          rgba(52, 57, 81, 0.95) -0.39%,
          rgba(40, 45, 71, 0.95) 104.15%);
      color: rgba(255, 255, 255, 1);
      border: none;
      border-bottom: 1px solid $orange-100;

      @media (min-width: $large) {
        border: 1px solid $blue-400;
      }

      &:hover {
        border-bottom: 1px solid $orange-100;

        @media (min-width: $large) {
          border: 1px solid $blue-400;
        }
      }
    }
  }

  &.results-tab-long {
    .nav-link {
      font-size: 12px;
      @media (min-width: $xx-large) {
        padding: 0;
      }
    }
  }
}

.results-sub-tabs {
  padding: 0;
  height: 52px;
  margin-top: 0;

  @media (min-width: $large) {
    margin-top: -4px;
  }

  .nav-link {
    padding: 0;
    border: none;
    background: transparent;
    border-radius: 0;

    &:hover {
      border: none;
    }
  }

  .nav-link.active {
    padding: 0;
    background: transparent;
    border: none;
    border-bottom: 1px solid $orange-100;

    &:hover {
      border: none;
      border-bottom: 1px solid $orange-100;
    }

    @media (min-width: $large) {
      background: transparent;
      border: none;
      border-bottom: 1px solid $orange-100;
    }
  }
}

.results-sub-tabs-holder {
  .tab-content {
    height: calc(100% - 55px) !important;

    @media (min-width: $large) {
      height: calc(100% - 55px) !important;
    }
  }
}

.bet-rounds {
  .accordion-item {
    border: 1px solid;
    border-image-source: linear-gradient(123.14deg,
        rgba(255, 255, 255, 0.1) -2.09%,
        rgba(255, 255, 255, 0) 108.83%);
    background-color: #343951;
    margin-bottom: 10px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  .accordion-button {
    background: linear-gradient(136.75deg,
        rgba(52, 57, 81, 0.8) -0.39%,
        rgba(40, 45, 71, 0.8) 104.15%);
    font-family: $font-semibold;
    font-size: 16px;
    color: white;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    padding: 16px 10px;

    &:focus {
      box-shadow: none;
    }

    &:after {
      width: 8px;
      height: 8px;
      background-image: none;
      border: solid $orange-100;
      border-width: 0 2px 2px 0;
      display: inline-block;
      padding: 2px;
      transform: rotate(45deg);
      -webkit-transform: rotate(45deg);
    }

    &:not(.collapsed)::after {
      transform: rotate(225deg);
      -webkit-transform: rotate(225deg);
    }
  }

  .accordion-body {
    position: relative;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    padding: 16px 2px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @media (min-width: $large) {
      justify-content: flex-start;
    }
  }

  .accordion-body-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #343951;
    opacity: 0.03;
    background-image: linear-gradient(135deg, #ffffff 25%, transparent 25%),
      linear-gradient(225deg, #ffffff 25%, transparent 25%),
      linear-gradient(45deg, #ffffff 25%, transparent 25%),
      linear-gradient(315deg, #ffffff 25%, #343951 25%);
    background-position: 6px 0, 6px 0, 0 0, 0 0;
    background-size: 6px 6px;
    background-repeat: repeat;
  }
}

.bet-round-time {
  padding: 0 5px;
  height: 18px;
  line-height: 18px;
  font-family: $font-regular;
  font-size: 12px;
  color: $gray-200;
  background-color: $gray-300;
  border-radius: 10px;
  margin-left: 10px;
}