// Results tab => Game/Bet Rounds

.bet-rounds-ball-holder {
  position: relative;
  padding: 0 8px;
  width: 48px;

  @media (min-width: $large) {
    width: 14.28%;
  }
}

.bet-rounds-ball-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  border-radius: 100%;
}

.bet-rounds-ball {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $font-semibold;
  color: rgba(16, 24, 49, 0.4);
  border-radius: 100%;
  padding: 4px;
  font-size: 12px;

  @media (min-width: $xx-large) {
    font-size: 14px;
  }
}

.bet-rounds-ball-border {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: 1px solid rgba(16, 24, 49, 0.5);
}

.bet-rounds-ball-position {
  font-family: $font-semibold;
  font-size: 14px;
  color: #181e34;
  height: 20px;
  text-align: left;
}

.bet-rounds-ball-odd {
  font-family: $font-light;
  font-size: 12px;
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
  height: 18px;
}

// Results tab => Game/All Bets and Game/My Bets

.results-ball {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  line-height: 20px;
  border-radius: 100%;
  font-family: $font-semibold;
  font-size: 14px;
  color: rgba(16, 24, 49, 0.4);
  margin: 2px;

  .flame-ball {
    position: absolute;
    top: -15px;
    right: -12px;
  }

  .flame-ball-small {
    position: absolute;
    top: -13px;
    right: -6px;
  }

  .flame-ball-fire-small {
    width: 21px;
    height: 21px;
  }
}

// Lootery Ball

.lottery-drum-ball-holder {
  position: absolute;
  background-color: #0A0D2E;
  width: 22px;
  height: 22px;
  border-radius: 100%;

  &.active {
    .lottery-ball {
      z-index: 2;
      animation: jumping-ball 0.5s ease-in-out normal forwards;
    }
  }
}

@keyframes jumping-ball {
  0% {
    transform: scale(0.01);
  }

  50% {
    transform: scale(1.25);
  }

  100% {
    transform: scale(1);
  }
}

.lottery-ball {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  font-family: $font-semibold;
  font-size: 14px;
  line-height: 1.5;
  color: rgba(16, 24, 49, 0.4);
  border: 1px solid;
  z-index: 1;
  @include transition(.3s transform ease-in-out);

  &.red-ball {
    box-shadow: 0px 0px 10px $red-ball;
    outline-color: darken($red-ball, 30%);
  }

  &.green-ball {
    box-shadow: 0px 0px 10px $green-ball;
    border-color: darken($green-ball, 30%);
  }

  &.blue-ball {
    box-shadow: 0px 0px 10px $blue-ball;
    border-color: darken($blue-ball, 30%);
  }

  &.purple-ball {
    box-shadow: 0px 0px 10px $purple-ball;
    border-color: darken($purple-ball, 30%);
  }

  &.brown-ball {
    box-shadow: 0px 0px 10px $brown-ball;
    border-color: darken($brown-ball, 30%);
  }

  &.yellow-ball {
    box-shadow: 0px 0px 10px $yellow-ball;
    border-color: darken($yellow-ball, 30%);
  }

  &.orange-ball {
    box-shadow: 0px 0px 10px $orange-ball;
    border-color: darken($orange-ball, 30%);
  }

  &.black-ball {
    box-shadow: 0px 0px 10px $black-ball;
    border-color: darken($black-ball, 30%);
  }

}

// Choose Numbers 

.choose-numbers-ball-holder {
  position: relative;
  padding: 2px;
  width: 16.666%;

  @media (min-width: $small) {
    padding: 4px;
    width: 12.5%;
  }

  @media (min-width: $xx-large) {
    padding: 6px;
  }

  &.disabled {
    opacity: .5;
  }
}

.choose-numbers-ball-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  border-radius: 100%;
}

.choose-numbers-ball {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $font-regular;
  color: white;
  border-radius: 100%;
  width: 100%;
  padding: 4px;
  border: 3px solid;
  background-color: rgba(10, 13, 46, .5);
  border-color: transparent;
  @include noSelect;
  font-size: 4.45vw;

  @media (min-width: $small) {
    font-size: 18px;
  }

  @media (min-width: $xx-large) {
    font-size: 20px;
  }

  &:disabled {
    cursor: not-allowed;
  }

  &.red-ball,
  &.green-ball,
  &.blue-ball,
  &.purple-ball,
  &.brown-ball,
  &.yellow-ball,
  &.orange-ball,
  &.black-ball {
    background-color: rgba(10, 13, 46, .5);
  }

  &.active {
    font-family: $font-bold;
    animation: choose-ball-bounce .3s ease-in-out normal forwards;

    .choose-numbers-ball-border {
      border-color: white;
    }

    &.red-ball {
      background-color: $red-ball;
      box-shadow: 0px 0px 10px $red-ball;
      border-color: darken($red-ball, 30%);
    }

    &.green-ball {
      background-color: $green-ball;
      box-shadow: 0px 0px 10px $green-ball;
      border-color: darken($green-ball, 30%);
    }

    &.blue-ball {
      background-color: $blue-ball;
      box-shadow: 0px 0px 10px $blue-ball;
      border-color: darken($blue-ball, 30%);
    }

    &.purple-ball {
      background-color: $purple-ball;
      box-shadow: 0px 0px 10px $purple-ball;
      border-color: darken($purple-ball, 30%);
    }

    &.brown-ball {
      background-color: $brown-ball;
      box-shadow: 0px 0px 10px $brown-ball;
      border-color: darken($brown-ball, 30%);
    }

    &.yellow-ball {
      background-color: $yellow-ball;
      box-shadow: 0px 0px 10px $yellow-ball;
      border-color: darken($yellow-ball, 30%);
    }

    &.orange-ball {
      background-color: $orange-ball;
      box-shadow: 0px 0px 10px $orange-ball;
      border-color: darken($orange-ball, 30%);
    }

    &.black-ball {
      background-color: $black-ball;
      box-shadow: 0px 0px 10px $black-ball;
      border-color: darken($black-ball, 30%);
    }
  }

}

.choose-numbers-ball-border {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: 1px solid;
  border-color: rgba(255, 255, 255, 0.3);
}

// Live Game 

.current-round-ball-holder {
  position: relative;
  padding: 0 6px;
  width: 48px;

  @media (min-width: $large) {
    width: 8.333%;
  }

  @media (min-width: $x-large) {
    width: 45px;
  }

  @media (min-width: $xx-large) {
    padding: 0 7px;
    width: 54px;
  }
}


.current-round-ball-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  border-radius: 100%;
}

.current-round-ball {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $font-semibold;
  color: rgba(16, 24, 49, 0.4);
  border-radius: 100%;
  padding: 4px;
  font-size: 12px;

  @media (min-width: $x-large) {
    font-size: 14px;
  }

  @media (min-width: $xx-large) {
    font-size: 16px;
  }

  &.red-ball,
  &.green-ball,
  &.blue-ball,
  &.purple-ball,
  &.brown-ball,
  &.yellow-ball,
  &.orange-ball,
  &.black-ball {
    animation: choose-ball-bounce .6s ease-in-out normal forwards;

  }
}

.current-round-ball-border {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: 1px solid rgba(16, 24, 49, 0.5);
}


// Your Ticket and Active Ticket

.your-ticket-ball-holder {
  position: relative;
  padding: 2.5px;
  width: 44px;

  &:nth-child(-n+6) {

    .default-ball {
      background-color: #0A0D2E;
    }
  }

  .flame-ball {
    position: absolute;
    top: -12px;
    right: -10px;
    z-index: 1;
  }
}


.your-ticket-ball-wrapper {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  border-radius: 100%;
}

.your-ticket-ball {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $font-semibold;
  color: rgba(16, 24, 49, 0.4);
  border-radius: 100%;
  padding: 4px;
  font-size: 14px;
  border: 2px solid #191E34;


  &.red-ball.winning-ball {
    background-color: $red-ball;
    box-shadow: 0px 0px 10px $red-ball;
  }

  &.green-ball.winning-ball {
    background-color: $green-ball;
    box-shadow: 0px 0px 10px $green-ball;
  }

  &.blue-ball.winning-ball {
    background-color: $blue-ball;
    box-shadow: 0px 0px 10px $blue-ball;
  }

  &.purple-ball.winning-ball {
    background-color: $purple-ball;
    box-shadow: 0px 0px 10px $purple-ball;
  }

  &.brown-ball.winning-ball {
    background-color: $brown-ball;
    box-shadow: 0px 0px 10px $brown-ball;
  }

  &.yellow-ball.winning-ball {
    background-color: $yellow-ball;
    box-shadow: 0px 0px 10px $yellow-ball;
  }

  &.orange-ball.winning-ball {
    background-color: $orange-ball;
    box-shadow: 0px 0px 10px $orange-ball;
  }

  &.black-ball.winning-ball {
    background-color: $black-ball;
    box-shadow: 0px 0px 10px $black-ball;
  }
}

.your-ticket-ball-border {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  border-radius: 100%;
  border: 1px solid rgba(16, 24, 49, 0.5);
}

@keyframes choose-ball-bounce {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.15);
  }

  100% {
    transform: scale(1);
  }
}