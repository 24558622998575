.gutter-2.row {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}
.gutter-2 > [class^="col-"],
.gutter-2 > .col,
.gutter-2 > [class^=" col-"] {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.gutter-3.row {
  margin-right: -0.25rem;
  margin-left: -0.25rem;
}
.gutter-3 > [class^="col-"],
.gutter-3 > .col,
.gutter-3 > [class^=" col-"] {
  padding-right: 0.25rem;
  padding-left: 0.25rem;
}
