@media (max-height: 900px) and (min-width: 1200px) {
    .choose-numbers-body {
        max-width: 475px;
        width: 100%;
    }

    .live-game-body {
        padding: 0;
    }

    .choose-amount-input-holder,
    .choose-amount-button {
        height: 36px;
    }

    .place-bet-wrapper {
        margin-top: .5rem;
    }

    .game-header {
        margin-bottom: .5rem;
    }

    .your-ticket-footer {
        margin-top: 0;
    }

    .ticket-holder {
        min-height: 170px;
    }

    .d-flex-live {
        margin-bottom: 0;
    }

    .choose-amount-button-wrapper {
        margin-top: 5px;
    }

    .choose-numbers-ball-holder {
        padding: 4px;
    }

    .game-holder {
        padding: .25rem;
        padding-bottom: .25rem;
    }

    .game-holder-left {
        height: 100vh;
        max-height: 788px;
    }

    .game-holder-right {
        padding: 5px;
    }

    .bottom-game-holder {
        margin-top: 5px;
    }

    body {
        zoom: 0.916;
    }
}