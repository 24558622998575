.game-rules-holder {
    position: absolute;
    top: 55px;
    left: 0;
    right: 0;
    z-index: 1000;
    padding: 0.5rem 0;
}

.game-rules-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    h1.game-rules-title {
        margin-bottom: 0;
    }
}

.game-rules-body {
    background-color: #101831;
    padding: 1rem;
    border-radius: 0;

    @media (min-width: $large) {
        border-radius: 10px;
    }
}

h1.game-rules-title {
    font-family: $font-semibold;
    font-size: 20px;
    color: white;
    margin-bottom: 1rem;
    text-transform: uppercase;
}

// .general-rules-images {
//     justify-content: center;
// }

.general-rules-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    h5 {
        font-family: $font-semibold;
        font-size: 16px;
        color: white;
    }

    img {
        width: 240px;
        height: 240px;
        margin-bottom: 1rem;
    }

    p {
        margin-bottom: 0;
        max-width: 240px;
        text-align: center;
    }
}

.game-rules-table {
    display: flex;
    justify-content: center;
    margin: 0 auto;
    margin-bottom: 2rem;

    @media (min-width: $large) {
    max-width: 500px;
    }

    table {
        border-collapse: collapse;
        margin: 0 .5rem;
    }

    td,
    th {
        border: 1px solid white;
        color: white;
        font-size: 14px;
        font-family: $font-regular;
        padding: .25rem .5rem;
    }

    th {
        font-family: $font-semibold;
    }

}