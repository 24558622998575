.user-message-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  &.new-message {
    animation: bounce-msg 1s ease-in-out normal forwards;
    @include transition(.3s transform ease-in-out);
  }
}

@keyframes bounce-msg {
  0% {
    transform: scale(0);
  }

  50% {
    transform: scale(1.05);
  }

  100% {
    transform: scale(1);
  }
}

.user-message-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.user-message {
  padding: 16px 20px;
  font-family: $font-regular;
  font-size: 16px;
  color: white;
  background-color: $gray-300;
  border-radius: 15px;
  border-bottom-left-radius: 0;
  max-width: 245px;

  @media (min-width: $xx-large) {
    max-width: 290px;
  }
}

.gif-message {
  max-width: 220px;
  border-radius: 15px;
  border-bottom-left-radius: 0;
  background-color: $gray-300;

  img {
    max-width: 100%;
    object-fit: cover;
    border-radius: 15px;
    border-bottom-left-radius: 0;
  }
}

.user-message-likes {
  padding: 0 10px;
  margin-left: -10px;
  height: 22px;
  background: rgba(24, 29, 52, 0.95);
  border-radius: 15px;
  font-family: $font-regular;
  font-size: 14px;
  color: white;
}

.message-button-like,
.message-button-fire {
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: $font-regular;
  font-size: 14px;
  padding: 0 10px;
  height: 22px;
  border: 1px solid $orange-100;
  background: rgba(251, 144, 45, 0.1);
  border-radius: 24px;
  margin: 0 5px;
  cursor: pointer;
}

.current-user-message-container {
  .user-message-wrapper {
    flex-direction: row-reverse;
  }

  .user-message {
    border-radius: 15px;
    border-bottom-right-radius: 0;
    background-color: $blue-ball;
  }

  .results-box-heder {
    justify-content: flex-end;
  }

  .user-message-likes {
    margin-left: 0;
    margin-right: -10px;
  }

  .gif-message {
    border-radius: 15px;
    border-bottom-right-radius: 0;
    background-color: $blue-ball;

    img {
      border-radius: 15px;
      border-bottom-right-radius: 0;
    }
  }
}