.results-box {
  cursor: default;
  background: linear-gradient(
    136.75deg,
    rgba(52, 57, 81, 0.8) -0.39%,
    rgba(40, 45, 71, 0.8) 104.15%
  );
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;

  &:hover {
    background: linear-gradient(
      36.75deg,
      rgb(55, 65, 85, 0.5) -0.39%,
      rgba(45, 65, 75, 0.5) 104.15%
    );
  }
}

.results-box-heder {
  display: flex;
  align-items: center;
}

.results-user-avatar {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 20px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
    object-fit: cover;
  }
}

.results-user-name {
  margin: 0 5px;
  font-family: $font-regular;
  font-size: 14px;
  color: white;
}

.results-user-flag {
  margin-top: -2px;
}

.results-balls-holder {
  display: flex;
  margin: 10px -2px 10px -2px;
}

.results-balls-holder-exstended {
  display: flex;
  margin: 10px -2px 10px -2px;
  justify-content: space-between;
}

.more-info {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #101831;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  margin-left: auto;
}

.mybet-tooltip {
  color: red;
}

.results-box-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.results-box-footer-right,
.results-box-footer-left {
  display: flex;
  align-items: flex-end;
}

.results-box-footer-right {
  font-family: $font-regular;
  font-size: 12px;
  color: $blue-500;
}

.results-box-footer-left {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex: 1;
}

.results-box-footer-info {
  font-family: $font-regular;
  font-size: 14px;
  color: $blue-700;
  display: flex;
  flex-direction: column;

  label {
    color: $blue-600;
    margin-right: 4px;
  }
}

.results-box-footer-info-win {
    color: white;
    margin-right: 4px;
}

.topwin-info {
  display: flex;
  flex-direction: column;
  text-transform: capitalize;
  font-family: $font-regular;
  font-size: 14px;
  color: $blue-700;
  margin-right: 10px;

  label {
    color: $blue-600;
    margin-right: 4px;
  }
}

.topWinH {
  display: flex;
  flex-direction: row;
  text-transform: capitalize;
  font-family: $font-regular;
  font-size: 14px;
  color: $blue-700;
  margin-bottom: 10px;

  label {
    color: $blue-600;
    margin-right: 4px;
  }
}
