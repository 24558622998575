.choose-numbers {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 10px;
    background: linear-gradient(136.75deg, rgba(52, 57, 81, 0.8) -0.39%, rgba(40, 45, 71, 0.8) 104.15%);
    border-radius: 5px;
    width: 100%;

    @media (min-width: $x-large) {
        width: 60%;
    }
}

.choose-numbers-body {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    width: 100%;

    @media (min-width: $small) {
        width: 536px;
    }

    @media (min-width: $large) {
        max-width: 536px;
        width: 100%;
    }

    @media (min-width: $xx-large) {
        max-width: none;
        width: 528px;
    }
}