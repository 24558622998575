.chat-wrapper {
  width: 100%;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: $blue-200;
  padding: 10px;
  padding-bottom: 60px; // .chat-page-input-holder height 
  overflow: auto;
  height: calc(100% - 125px); // .chat-page-input-holder height + .bottom-navigator height + 15px padding

  @media (min-width: $large) {
    position: relative;
    width: auto;
    height: 100%;
    padding-bottom: 95px; // .chat-page-input-holder height + 10px
  }

}

.chat-online {
  font-family: $font-regular;
  font-size: 12px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: white;
  padding: 0 0.5rem;
}

.chat-online-span {
  width: 6px;
  height: 6px;
  border-radius: 6px;
  background-color: #a7e623;
  margin: 0 4px -1px;
}

.chat-wrapper-online {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 20px;
  background-color: #101831;
}

.chat-holder {
  overflow: scroll;
}

.chat-badge-bottom-navigator {
  position: absolute;
  top: 5px;
  left: 50%;
}

.chat-page-input-holder {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 15px;
  background: rgba(16, 21, 43, 0.9);
  border-top: 1px solid rgba(255, 255, 255, 0.22);
  height: 60px;

  @media (min-width: $large) {
    height: 85px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }
}

.chat-page-input-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.gif-emoji-holder {
  display: flex;
  align-items: center;
  margin-left: 1rem;
}

.chat-page-input {
  width: 100%;
  height: 30px;
  font-family: $font-regular;
  font-size: 16px;
  color: white;
  background-color: transparent;
  border: none;

  &:focus {
    outline: none;
  }
}

.char-counter-holder {
  position: relative;
  font-family: $font-light;
  font-size: 12px;
  color: rgba(255, 255, 255, 0.22);
  margin-bottom: 0;
  margin-left: 1rem;

  @media (min-width: $large) {
    position: absolute;
    bottom: 20px;
    margin-left: 0;
  }
}

.message-time {
  margin-left: 5px;
  font-family: $font-regular;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.2);
}

.message-buttons {
  display: flex;
  align-items: center;
  margin-left: 1rem;
}

.new-message-separator {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #182243;
  line-height: 0.1em;
  margin: 10px 0 25px;
  color: $blue-500;
  font-family: $font-semibold;
  font-size: 12px;

  span {
    background: $blue-200;
    padding: 0 10px;
  }
}

.end-message {
  position: relative;
}