.header {
  width: 100%;
  height: 55px;
  background-color: rgba(24, 29, 52, 0.95);
  padding: 0 10px;

  @media (min-width: $large) {
    padding: 0;
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }
}

.header-button {
  padding: 0;
  margin-right: 3px;
  border: none;
  background: transparent;
}

.questionmark-button {
  margin-right: 2rem;
}

.menu-button {
  position: relative;

  .menu-button-indicator {
    position: absolute;
    top: 0;
    right: -6px;
    width: 10px;
    height: 10px;
    border: 2px solid $primary-color;
    background-color: $red-100;
    border-radius: 100%;
  }
}