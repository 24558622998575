p {
    font-family: $font-regular;
    font-size: 14px;
    color: white;

    &.red-text {
        color: red;
    }
}

b {
    font-weight: inherit;
    font-family: $font-semibold;
}