.chat-badge {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-family: $font-semibold;
    font-size: 12px;
    color: white;
    height: 18px;
    border-radius: 9px;
    padding: 5px;
    line-height: 18px;
    background-color: $orange-100;
    margin-left: 5px;
    &.blue {
      background-color: #3bc4f2;
    }
}