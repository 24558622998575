$font-light: "Kanit-Light", Roboto-Light;
$font-regular: "Kanit-Regular", Roboto-Regular;
$font-drum: "Tanker-Regular", Roboto-Regular;
$font-medium: "Kanit-Medium", Roboto-Medium;
$font-semibold: "Kanit-SemiBold", Roboto-Medium;
$font-bold: "Kanit-Bold", Roboto-Bold;

$primary-color: #181d34;

$red-100: #ff4000;

$gray-100: #e5e5e5;
$gray-200: #B8BDD8;
$gray-300: #515877;

$blue-100: #30395a;
$blue-200: #101831;
$blue-300: #353d62; // tab border
$blue-400: #343951; // tab border results
$blue-500: #494e67;
$blue-600: #8f96bc;
$blue-700: #c4c8de;

$orange-100: #fb902d; // tab active border

$red-ball: #FF1EE4;
$green-ball: #1CE287;
$blue-ball: #3D95FF;
$purple-ball: #5928FB;
$brown-ball: #BE8C63;
$yellow-ball: #ded200;
$orange-ball: #FB902D;
$black-ball: #787A91;

$small: 576px;
$medium: 768px;
$large: 1024px;
$x-large: 1200px;
$xx-large: 1400px;
