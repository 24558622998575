.current-round-results-holder {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex: 1;
    margin-left: 0;
    margin-top: 1rem;

    @media (min-width: $x-large) {
        margin-left: 2rem;
        margin-top: 0;
        justify-content: flex-start;
    }

    @media (min-width: $xx-large) {
        margin-left: 5%;
    }
}