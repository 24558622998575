@media (max-height: 750px) and (max-width: 576px) {
    .lottery-drum {
        width: 264px;
        height: 264px;
    }
    .lottery-drum-balls-holder {
        top: 3px;
        left: 3px;
    }
    .lottery-drum-ball-holder {
        width: 20px;
        height: 20px;
    }
    .drum-arrow {
        height: 268px;
    }
    .lottery-drum-number-big {
        font-size: 58px;
    }
    .lottery-ball {
        font-size: 12px;
    }
    .active-ticket-game-header {
        margin-bottom: .5rem;
    }
    .your-ticket-ball-holder {
        width: 40px;
    }
    .ticket-holder-body {
        min-height: 48px;
    }
    .active-ticket-footer-top {
        border-bottom: none;
        padding: 5px 0 0 0;
    }
    .active-ticket-footer-bottom {
        padding-top: 0;
    }
    .ticket-holder {
        min-height: auto;
        padding: 5px;
    }
}