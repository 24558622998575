.live-game {
  position: relative;
  padding: 5px 5px 10px 5px;
  background-color: rgba(52, 57, 81, 0.8);
  border-radius: 6px;

  @media (min-width: $large) {
    padding: 10px;
  }

  .live-game-background {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #343951;
    opacity: 0.03;
    background-image: linear-gradient(135deg, #ffffff 25%, transparent 25%),
      linear-gradient(225deg, #ffffff 25%, transparent 25%),
      linear-gradient(45deg, #ffffff 25%, transparent 25%),
      linear-gradient(315deg, #ffffff 25%, #343951 25%);
    background-position: 6px 0, 6px 0, 0 0, 0 0;
    background-size: 6px 6px;
    background-repeat: repeat;
    border-radius: 6px;
  }
}

.bonus-live {
  background: #FDEB71;
  background-image: linear-gradient(135deg, #FDEB71 10%, #F8D800 100%);

  .game-header-live-title,
  .bet-rounds-ball-odd,
  .bet-rounds-ball-odd-bonus,
  .jackpot-btn,
  .jackpot-btn-title,
  span.statNumberStyle div,
  .game-header-text {
    color: #181e34;
  }

  .jackpot-btn {
    border-color: #3d95ff;
    box-shadow: 0px 0px 10px #3d95ff;

    &:hover {
      border-color: #3d95ff;
      color: #181e34;
    }
  }

  .jackpot-currency {
    color: rgba(0, 0, 0, 0.6);
  }

}

.game-header-live {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
  font-family: $font-semibold;
  font-size: 14px;
  color: white;
}

.jackpot-wrapper {
  display: flex;
  width: 100%;
  z-index: 999;
  justify-content: center;

  @media (min-width: $x-large) {
    justify-content: flex-start;
    margin-left: 355px;
  }

  @media (min-width: $x-large) and (max-height: 900px) {
    margin-left: 345px;
  }

  @media (min-width: $xx-large) {
    margin-left: 400px;
  }

  @media (min-width: $xx-large) and (max-height: 900px) {
    margin-left: 370px;
  }
}

.d-flex-live {
  display: flex;
  justify-content: center;
  margin-top: -16px;
  margin-bottom: 10px;

  @media (min-width: $large) {
    margin-top: -15px;
  }

  @media (min-width: $x-large) {
    margin-top: -48px;
  }
}

.game-header-text-bonus {
  color: #000000;
}

.live-game-icon {
  margin-right: 6px;
  margin-top: -1px;
}

.live-game-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 0 .5rem;

  @media (min-width: $x-large) {
    flex-direction: row;
    padding: 1rem .5rem;
  }

  @media (min-width: $xx-large) {
    padding: .5rem 2.5rem;
  }
}