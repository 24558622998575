.ticket-position {
flex: 1;

@media (min-width: $x-large) {
flex: 0;
}

    &.active-game {
        position: fixed;
        bottom: 70px;
        left: 0;
        right: 0;
        z-index: 999;
        padding: 0 10px;

        .ticket-holder {
            background: linear-gradient(136.75deg, rgba(52, 57, 81, 1) -0.39%, rgba(40, 45, 71, 1) 104.15%);
        }
    }
}

.ticket-holder {
    display: flex;
    flex-direction: column;
    padding: 10px;
    background: linear-gradient(136.75deg, rgba(52, 57, 81, 0.8) -0.39%, rgba(40, 45, 71, 0.8) 104.15%);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    min-height: 187px;
}

.your-ticket-clear {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 26px;
    padding: 0 8px;
    background-color: rgba(255, 255, 255, 0.1);
    font-family: $font-regular;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.5);
    border: none;
    border-radius: 13px;
    @include noSelect;
}

.autoplay-badge-number {
    color: white;
}

.your-ticket-clear-icon {
    margin-left: .5rem;
}

.ticket-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.ticket-holder-body {
    display: flex;
    justify-content: center;
    min-height: 65px;

}

.ticket-holder-body-wrapper {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 264px;
}

.your-ticket-footer {
    margin-top: 1rem;

    .active-ticket-footer-top {
        border-bottom: none;
        padding: 0;
    }
}

.active-ticket-footer-top {
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #394067;
    margin: 0 -5px;
    padding: 5px 0;
}

.active-ticket-footer-label {
    padding: 0 5px;
    font-family: $font-regular;
    font-size: 14px;
    color: #8F96BC;

    span {
        color: #C4C8DE;
    }
}

.active-ticket-footer-bottom {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;

    .active-ticket-footer-label {
        span {
            color: white;
        }
    }
}