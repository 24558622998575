.lottery-drum {
    position: relative;
    width: 304px;
    height: 304px;
    border-radius: 100%;
    border: 1px solid #181B34;
    display: flex;
    justify-content: center;
    align-items: center;
    // overflow: hidden;
    background-size: cover;
    background-position: center;
    background-image: url(../../assets/images/lottery-drum-background.png);

    .CircularProgressbar {
        filter: drop-shadow(0px 0px 8px #FB902D);
    }

    .CircularProgressbar-trail {
        stroke: rgba(10, 13, 46, .3) !important;
        stroke-width: 1px !important;
    }

    .CircularProgressbar-path {
        stroke: $orange-100  !important;
        stroke-width: 4px !important;
    }


    // .CircularProgressbar-trail {
    //     stroke: $orange-100 !important;
    // }
    // .CircularProgressbar-path {
    //     stroke: #0A0D2E !important;
    // }

    // .CircularProgressbar-text {
    //     font-family: $font-drum;
    //     font-size: 46px !important;
    //     fill: white !important;
    // }
}

.lottery-drum-balls-holder {
    position: absolute;
    top: 4px;
    left: 4px;
    border-radius: 100%;
}

.lottery-drum-seconds {
    font-family: $font-drum;
    font-size: 66px;
    color: white;

    span.seconds {
        display: inline-block;
        // width: 30px;
        animation-duration: .7s; // drum counter seconds animation duration
    }

    span.seconds-mark-holder {
        font-size: 20px;
        margin-left: .5rem;
    }
}

.lottery-drum-number {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.lottery-drum-number-big {
    font-family: $font-drum;
    font-size: 66px;
    line-height: 1.1;
    color: white;
    margin-bottom: 0.25rem;
}

.lottery-drum-number-odd {
    font-family: $font-drum;
    font-size: 20px;
    line-height: 20px;
    color: white;
    height: 20px;
}

.drum-middle-holder {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.drum-middle {
    width: 185px;
    height: 185px;
    margin-bottom: -6px;
    margin-left: 6px;
}

.lottery-drum-background {
    background: #023BFF;
    opacity: 0.5;
    filter: blur(50px);
    -webkit-filter: blur(50px);
    -moz-filter: blur(50px);
    -o-filter: blur(50px);
    -ms-filter: blur(50px);
    position: absolute;
    top: 32px;
    left: 32px;
    right: 32px;
    bottom: 32px;
    border-radius: 100%;
}

.drum-arrow {
    position: absolute;
    // top: 28px;
    z-index: 1;
    @include transition(.2s all ease-in-out);
}